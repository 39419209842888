.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 15px;
}

.header{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    padding-bottom: 10px;
}

.header_title{
    font-size: 1.2rem;
    /* font-weight: bold; */
}

.list{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.list_item{
    padding: 2px 6px;
    border: 1px solid #0000003d;
    border-radius: 3px;
    color: #00000086;

    white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.list_item:hover{
    cursor: pointer;
    border: 1px solid #00000086;
    color: #000000;
}

.festival{
    background-color: #36a49b;
    color: white;
}

.value{
    background-color: cyan;
    color: black;
}

.meme{
    background-color: purple;
    color: white;
}