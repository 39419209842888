

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

@media screen and (max-width: 600px) {
    .grid{
        grid-template-columns: 1fr;
    }
    
}