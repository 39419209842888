.wrapper {
    width: 300px;
    height: 100vh;
    background-color: white;
    box-shadow: inset 0px 0px 29px -8px rgba(61, 61, 61, 1);
    transition: 0.5s;
    position: relative;
}

.handle {
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 45px;
    background-color: royalblue;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
}

.title {
    padding: 10px 20px;
    background-color: black;
    color: white;
    text-align: center;
}

.subtitleWrapper {
    padding: 10px 15px;
    margin-bottom: 10px;
}

.itemWrapper {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: 0.2s;
}

.item {
    padding-right: 12px;
    border: 1px solid #dfdfdf;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.name {
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    flex: 1;
    cursor: pointer;
}

.iconBtn {
    color: rgb(165, 165, 165);
    cursor: pointer;
}

.item:hover {
    background-color: #dfdfdf;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
