.wrapper {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: white;
    padding: 1em 2em;
}

.tabWrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
    border-bottom: 1px solid rgb(216, 216, 216);
}

.tab {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: center;
    width: 7em;
    border: 1px solid rgb(240, 240, 240);
    cursor: pointer;
}

.selected {
    font-weight: bold;
    background-color: rgb(233, 233, 233);
}
