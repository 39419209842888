.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 100px;

    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.content_wrapper {
    padding: 10px;
    flex-grow: 1;
}

.square_img {
    /* background-image: url("https://images.news18.com/ibnlive/uploads/2021/07/1627377451_nature-1600x900.jpg"); */
    height: 100px;
    width: 100px;
    border-radius: 5px 0 0 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; /* you change this to "contain" if you don't want the images to be cropped */
}

.card .title {
    font-size: 1rem;
    font-weight: bold;
    color: rgb(73, 73, 73);
    padding-bottom: 5px;
    margin: 0;
}

.card .subtitle {
    font-size: 0.8rem;
    font-weight: bold;
    color: rgb(126, 126, 126);
    padding: 0;
    margin: 0;
}

.card .price {
    color: rgb(44, 145, 44);
    padding-right: 10px;
    font-size: 1.2rem;
    align-self: flex-end;
}
