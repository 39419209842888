:root {
    --form-border-color: #dddddd;
}

.img_add_block {
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid var(--form-border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 0.9em;
    color: #aaa;
    cursor: pointer;
}

.img_add_block i {
    font-size: 1.5em;
}

.preview-img {
    width: 150px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid var(--form-border-color);
    margin-top: 0.2em;
}

.img-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
}

.img-group .preview_wrapper {
    height: 100px;
    min-width: 100px;
    width: auto;
}

.img-group .preview_wrapper .preview_img {
    height: 100px;
    width: auto;
    min-width: 100px;
    object-fit: contain;
}

.img-group .img_add_block {
    width: 100px;
    height: 100px;
    margin-top: 0.2em;
}

.preview_wrapper {
    width: 150px;
    max-width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 1px solid var(--form-border-color);
    margin-top: 0.2em;
    position: relative;
    overflow: hidden;
}

.preview_img {
    height: 150px;
    width: auto;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}

.preview_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.452);
    opacity: 0;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.preview_overlay button {
    align-self: center !important;
}

.preview_overlay:hover {
    opacity: 1;
}

.preview_overlay i {
    font-size: 1.3em;
    cursor: pointer;
}
