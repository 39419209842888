.pan_h {
    animation: panning_h 50s infinite linear;
}

@keyframes panning_h {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
