.wrapper {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    background: #eeeeee;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    position: sticky;
    top: 10px;
    background-color: white;
    /* background-color: rgba(255, 255, 255, 0.253); */

    width: 800px;
    /* display: flex;
    flex-direction: row; */
    display: grid;
    /* grid-template-columns: 1fr auto 1fr; */
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    max-width: 100%;
}

.left_box {
    /* background-color: yellow; */
    flex: 1;
    height: 100%;
    background-image: url(https://unsplash.com/photos/Jrl_UQcZqOc/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTV8fG1pbmltYWwlMjBpbGx1c3RyYXRpb258ZW58MHx8fHwxNzAxMzE1MDc3fDA&force=true&w=640);
    background-size: cover;
    background-position: center;
}

.left_box img {
    height: 400px;
    object-fit: cover;
}

.divider_v {
    height: 70%;
    border-right: 2px solid #eee;
    margin: 0 20px;
}

.right_box {
    text-align: left;
    padding: 30px;
}

.heading {
    /* text-align: center; */
    margin-top: 10px;
    /* padding-bottom: 15px; */
}

.logo {
    height: 110px;
    padding-bottom: 25px;
    margin-top: -100px;
    position: sticky;
    top: 10px;
}

.logo_inside_wrapper {
    /* text-align: center; */
}

.logo_inside {
    height: 80px;
    padding-top: 10px;
    padding-bottom: 0px;
}

.card ::placeholder,
.card ::-webkit-input-placeholder,
.card .ant-select-selection__placeholder,
.card .ant-select-selection-placeholder {
    color: rgb(131, 131, 131) !important;
    opacity: 1 !important;
}

@media only screen and (max-width: 700px) {
    .wrapper {
        background-color: white;
        padding: 10px;
    }
    .card {
        box-shadow: none;
        border-radius: 0;
        grid-template-columns: 1fr;
    }
    .left_box {
        display: none;
    }
    .right_box {
        text-align: center;
    }
    .content {
        display: flex;
        justify-content: center;
    }
    .logo {
        height: 100px;
        padding-bottom: 15px;
    }
}
