.wrapper {
    /* position: fixed;
    left: 0;
    top: 0; */
    z-index: 50;

    width: 50px;
    height: 100%;
    /* height: 100vh; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.topbar {
    width: 100%;
    height: 50px;
    flex-direction: row;
    box-shadow: 0px 12px 16px -13px rgba(150, 150, 150, 1);
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 50px; */
    margin-top: 10px;
    /* border: 1px dashed yellow; */
}

.topbar .itemWrapper {
    flex-direction: row;
    margin-top: 0;
}

.item {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff99;
    font-weight: bold;
    font-size: 1.2rem;

    border-radius: 10px 0px 0px 10px;
    cursor: pointer;
}

.item:hover {
    transition: 0.2s;
    font-size: 1.5rem;
    color: white;
}

.item:active,
.selected {
    color: white;
    font-style: bold;
    /* background-color: darkblue; */
    font-size: 1.4rem;
}

.userIcon {
    width: 30px;
    height: 30px;

    border: 2px solid #ffffff99;
    border-radius: 10px;
}

/* .item:active,
.selected {
    background-color: white;
    color: black;
} */

@media only screen and (max-width: 700px) {
    .wrapper {
        position: sticky;
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        height: 50px;
        max-width: 100%;
        box-sizing: border-box;

        flex-direction: row;
        overflow-x: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .itemWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        /* background-color: yellow; */
        margin-top: 0;
    }

    .item {
        border-radius: 0;
    }

    .item:active,
    .selected {
        color: white;
        font-style: bold;
        /* background-color: darkblue; */
    }
}
