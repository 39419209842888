.heading {
  font-size: 1.7rem;
  font-weight: bolder;
  padding-bottom: 20px;
}

.wrapper {
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

/* ======================= */
.gridWrapperH {
  display: flex;
  flex-direction: row;
  gap: 12px;
  border: 1px dashed red;
  overflow: hidden;
}
/* ======================= */

.new_grid_wrapper {
  width: 100%;
  border: 1px dashed red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
  .gridWrapperV {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    column-gap: 10px;
    row-gap: 20px;
  }
  .gridItemImgV {
    height: 250px;
    border-radius: 10px;
  }
}
