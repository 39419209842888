.gridWrapper {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  column-gap: 20px;
  row-gap: 30px;
  padding-bottom: 30px;
}

.grid_wrapper_mobile {
  max-width: 100%;
  /* border: 2px dashed green; */
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  row-gap: 20px;
}

.top_bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
}

.folder {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddcb79;
  cursor: pointer;
  background-color: #f7f4e8;
  color: black;
}

.folder i {
  color: #ddcb79;
  padding-right: 10px;
}

.folder_selected {
  background-color: #ddcb79;
  color: black;
}

.folder_selected i {
  color: white;
}

@media (max-width: 768px) {
  .gridWrapper {
    max-width: 100%;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    row-gap: 15px;
  }
  .top_bar{
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
  }
}
