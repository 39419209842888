.wrapper {
  width: auto;
  padding: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  overflow: hidden;
  height: 120px;

  background: linear-gradient(
    112.1deg,
    rgb(32, 38, 57) 11.4%,
    rgb(63, 76, 119) 70.2%
  );
}

.left_img {
  object-fit: contain;
  height: 120px;
}

.left_box {
  padding-left: 20px;
  flex: 1;
}

.title {
  color: rgb(255, 255, 255);
}

.content {
  color: #ddd;
  line-height: 1.5em;
}

.right_box img {
  height: 120px;
  object-fit: contain;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    height: auto;
    flex-direction: row;
  }
  .left_box {
    padding-right: 10px;
  }
  .right_box {
    display: none;
  }
  .left_img {
    height: 100px;
  }
  .title {
    font-size: 1.2em;
  }
  .content {
    font-size: 0.8em;
  }
}
