.wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #EBECF0;
}

.img {
  /* height: 300px; */
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  background-color: #EBECF0;
  /* border-radius: 10px; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: 0;
  background-color: #00000081;
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.2s;
}

.wrapper:hover .overlay {
  opacity: 1;
  height: 100%;
}

/* ================================= */
.giBtn {
  padding: 7px 12px;
  background-color: white;
  color: black;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.loveBtn {
  position: absolute;
  top: 7px;
  right: 7px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}
