.wrapper {
    /* padding: 24px; */
    padding: 30px;
    min-height: 360px;
    overflow: auto;
    background-color: white;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 30px;
}

.left_wrapper {
    width: 250px;
}

.right_wrapper {
    flex: 1;
    /* background-color: yellow; */
}

.profile_pic {
    width: 100%;
}

.title {
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 0;
}

.subtitle {
    font-size: 1.2rem;
    margin-top: 0;
    font-style: italic;
}

.ilItem {
    padding-top: 10px;
    font-size: 1.1rem;

    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.ilText {
    color: blue;
}

@media only screen and (max-width: 768px) {
}
