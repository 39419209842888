:root {
    --table_cell_padding_v: 8px;
    --table_cell_padding_h: 15px;
}

.section_wrapper {
    /* border: 1px dashed gray; */
    padding: 15px 0px;
}

.section_title {
    font-weight: bold;
    color: black;
    padding-left: var(--table_cell_padding_h);
}

.table {
    width: 100%;
    margin: 0;
}

.tr {
    /* border: 1px solid gray; */
    border-radius: 10px;
    overflow: hidden;
}

.tr:hover {
    background-color: rgb(245, 245, 245);
}

.td {
    vertical-align: top;
    padding: var(--table_cell_padding_v) var(--table_cell_padding_h);
    /* border: 1px solid red; */
}

.label {
    width: 180px;
    color: #777;
    padding-right: 0;
}

@media only screen and (max-width: 768px) {
    .section_wrapper {
        padding: 0;
    }
    .label {
        min-width: 120px;
    }
}
