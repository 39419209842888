.qchangepassword {
    /* background-color: white; */
    padding: 20px;
    font-size: 14px;
}

.qchangepassword label {
    color: black;
    font-size: 0.95em;
    /* font-weight: bold; */
}

.qchangepassword label:after {
    color: #e32;
    content: " *";
    display: inline;
}


.qchangepassword input[type="password"]{
    
        outline: none;
        border: 1px solid #a8a8a8;
        padding: 0.4em 0.8em;
        line-height: 1.6em;
        border-radius: 4px;
        font-size: 1em;
        display: flex;
        flex-direction: column;
        gap: 0.25em;
        align-items: stretch;

}

.qchangepassword input:focus {
    border: 1px solid blue;
}


.qchangepassword button {
    align-self: flex-start;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 0.4em 1.2em;
    background-color: rgb(34, 139, 230);
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 20px;
    margin-bottom: 10px;
}


.qchangepassword button:hover {
    background-color: rgb(28, 126, 214);
}

.qchangepassword button[status="loading"] {
    filter: opacity(0.5);
}


