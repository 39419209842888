.screen {
    height: 100vh;
    /* overflow: auto; */
    background-color: #ecf1f3;
    flex: 1;
    /* max-width: 75vw; */
}

.wrapper {
    /* max-width: 900px; */
    max-width: 75vw;
    margin: 20px auto;
    /* padding: 10px 20px; */
}

.billing_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 20px -10px 20px;
    padding: 20px 20px;
    background-color: white;
    /* font-weight: bold; */
    font-size: 1rem;
    outline: 1px solid rgb(161, 161, 161);
}

.billing_wrapper .label {
    padding-left: 10px;
    padding-right: 60px;
    font-weight: 100;
}

.billing_wrapper .bold {
    font-weight: bold;
}

.billing_wrapper .value {
    font-weight: bold;
}

.billing_wrapper .light {
    font-weight: 100;
}

.header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.heading {
    font-weight: bolder;
}

.table_wrapper {
    margin-top: 20px;
    padding: 10px 20px;
    user-select: none;
    /* max-width: 76vw; */
}

.table {
    padding: 20px;
}

.table_header_cell {
    /* min-width: 200px; */
    margin: -16px;
    margin-bottom: -16px;
    padding: 2px;
    padding-top: 35px;
    padding-bottom: 16px;
}

.label {
    padding-left: 20px;
    font-weight: bold;
    color: darkblue;
}

.name {
    font-size: 1.2rem;
    font-weight: bold;
    color: darkblue;
    white-space: nowrap;
    margin-bottom: 5px;
}

.price {
    font-size: 2rem;
    font-weight: bold;
    color: black;
    white-space: nowrap;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
    .price {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 700px) {
    .label {
        padding-left: 0;
    }
    .name {
        font-size: 1rem;
    }
    .price {
        font-size: 1.3rem;
    }

    .header_wrapper {
        flex-direction: column;
    }

    .table_wrapper {
        padding-left: 2px;
        padding-right: 2px;
    }

    .billing_wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .billing_wrapper .label {
        padding-left: 0px;
        padding-right: 30px;
    }
}
