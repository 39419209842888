:root {
  --table-border-color: #dfdfdf;
  /* bars seperator */
  --border-color: #eee;
  --border-radius: 10px;

  --primary-color: #00998e;

  font-family: Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
}

/* .ant-input {
    border-color: #c9c7c7 !important;
}

.ant-select-selector {
    border-color: #c9c7c7 !important;
}

.ant-table {
    color: black !important;
}

.ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
.ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid var(--table-border-color) !important;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
    border-bottom: 1px solid var(--table-border-color) !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid var(--table-border-color) !important;
    border-top: 1px solid var(--table-border-color) !important;
}
.ant-table-thead > tr > th {
    color: black !important;
    font-weight: 600 !important;
}

.ant-form-item-label > label {
    color: rgb(26, 26, 26);
    font-weight: 600;
} */

.qureal-ant-table-row {
  cursor: pointer;
}


.q_gray{
    color: rgba(0, 0, 0, 0.381);
}