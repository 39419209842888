.sectionWrapper {
    padding: 10px;
    border: 1px solid #a8a8a8;
    margin-bottom: 10px;
}

.listWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4em;
}

.listItem {
    border: 1px solid #a8a8a8;
    padding: 0.4em 0.9em;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.actionsWrapper {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    color: rgba(0, 0, 0, 0.623);
    margin-right: -0.9em;
    margin-top: -0.2em;
}

.actionsWrapper i {
    padding: 7px;
    cursor: pointer;
}

.table tr td {
    padding-right: 20px;
}
