.q-table-label {
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    font-weight: normal;
}

.q-table-label-dot {
    width: 12px;
    height: 12px;
    border-radius: 10px;
}
