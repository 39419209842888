.page_wrapper {
    height: 100vh;
}

.wrapper {
    display: grid;
    background-color: cyan;
    z-index: -2;

    grid-template-columns: 50px 1fr;
    height: calc(100vh - 50px);
}

.app_area {
    background-color: antiquewhite;
}

@media only screen and (max-width: 700px) {
    .page_wrapper {
        height: 100%;
    }
    .wrapper {
        /* height: 60vh; */
        display: flex;
        flex-direction: column;
    }
    .app_area {
        flex: 1;
        max-height: calc(100vh - 100px);
    }
}
