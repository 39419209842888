.body_wrapper{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}


.main_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #ccc; */
    padding-top: 50px;
    max-width: 100%;

    padding-bottom: 20px;
}




.header_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-bottom: 15px;
}

.logo_square{
    height: 50px;
    width: 50px;
    border-radius: 5px;
}

.greeting_text{
    font-size: 2.5rem;
    line-height: 1rem;
    color: #555
}


.bottom_bar{
    width: 100%;
    /* background-color: yellow; */

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    padding: 15px;
    box-sizing: border-box;
}

.action_box{
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 15px;

    padding: 0 15px;
    text-align: center;
    transition: .2s;
    cursor: pointer;
    
    background-size: cover;;
}

.action_box h3{
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 17px 16px rgba(255,255,255,1);
}

.action_box p{
    color: rgba(0, 0, 0, 0.447);
}

.action_box:hover{
    transform: translateY(-5px);
}


.suggestions_bar{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: stretch;
    gap: 15px;
    padding-bottom: 15px;
    width: 800px;
    max-width: 100%;
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
}

.suggestion{
    border: 1px solid #ccc;
    padding: 10px;
    color: #00000079;
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    line-height: 1.3rem;
}

.suggestion:hover{
    background-color: #00000007;
}


@media screen and (max-width: 600px) {
    .greeting_text{
        font-size: 1.5rem;
    }
    
}