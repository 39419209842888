.gridItem {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.gridItemImg {
    /* height: 150px; */
    height: 180px;
    background-color: #ebecf0;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.gridItemImgV {
    height: 310px;
    background-color: #ebecf0;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.giImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    padding: 4px;

    object-fit: contain;
    /* object-fit: cover; */
}

.giBackImg {
    position: absolute;
    top: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
    /* width: 100%; */
    /* height: 100%; */
    outline: none;
    border: none;
    padding: 4px;

    background-size: cover;
    background-color: #f1fdf3;
}

.giOverley {
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    height: 0%;
    background-color: #00000071;
    opacity: 0;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* .giOverley:hover {
    opacity: 1;
    height: 100%;
} */

.gridItem:hover .giOverley {
    opacity: 1;
    height: 100%;
}

.gridItemTitle {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    padding-right: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gridItemSubtitle {
    color: gray;
    padding-top: 5px;
    font-size: 0.9rem;
    margin: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.giBtn {
    padding: 7px 12px;
    background-color: white;
    color: black;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 400px) {
    .gridWrapperV {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        column-gap: 10px;
        row-gap: 20px;
    }
    .gridItemImgV {
        height: 250px;
        border-radius: 10px;
    }
}
