 [type="date"] {
    background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
  }
 [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
 [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  

  
 