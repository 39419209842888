.filesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 10px;
}

.fileItem {
    width: 100%;
    height: 50px;
    border: 1px solid #e9e9e9;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.itemLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
}

.fileIcon {
    min-width: 50px;
    height: 50px;
    background-color: royalblue;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    transition: 0.3s;
}

.fileItem:hover .fileIcon {
    opacity: 1;
}

.fileName {
    color: black;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fileType {
    color: gray;
    font-size: 0.7rem;
}

.fileSize {
    font-size: 0.8rem;
    font-weight: bold;
    color: gray;
}

.btnDownload {
    background-color: transparent;
    color: #a1a1a1 !important;
}

.btnDownload:hover {
    color: royalblue !important;
}

.btnDelete {
    background-color: transparent;
    color: #a1a1a1 !important;
}

.btnDelete:hover {
    color: red !important;
}
