.body_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000004b;
    /* background-color: white; */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 15vh;
}


.ai_btn{
    background: linear-gradient(127deg, rgb(237, 202, 1) 0%, rgb(242, 222, 111) 49%, rgb(234, 185, 80) 100%);
    color: #473309;
    border: 1px solid #8f6710;
}




.work_area{
    max-width: 100%;
    display: flex;
    flex-direction: column;


    box-shadow: 0px 12px 29px -1px rgba(0,0,0,0.2);
    border-radius: 20px;
    overflow: hidden;


    /* From https://css.glass */
background: rgba(255, 255, 255, 0.66);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(12.6px);
-webkit-backdrop-filter: blur(12.6px);
border: 1px solid rgba(255, 255, 255, 0.34);
}

.search_box_wrapper{
    width: 600px;
    /* border-radius: 20px; */
    
    outline: none;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #0a0a0a1a; 
    max-width: 100%;

    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    align-items: center;
}

.search_box_input{
    flex: 1;
    padding: 10px 5px;
    font-size: 1.5em;
    /* background-color: white; */
    background-color: transparent;

    /* border: 1px dashed red; */
    border: none;
    outline: none;

    color: #0a0a0a;
}

.search_box_input::placeholder{
    color: #0a0a0a2f;
}

.search_box_input:hover,
.search_box_input:focus,
.search_box_input:active,
.search_box_input:visited{
    border: none;
    outline: none;
}

.search_box{
    width: 600px;
    /* border-radius: 20px; */
    padding: 10px 20px;
    font-size: 1.5em;
    /* background-color: white; */
    background-color: transparent;
    outline: none;
    border-radius: 10px 10px 0 0;
    border: none;
    border-bottom: 1px solid #0a0a0a1a; 
    max-width: 100%;
}

.search_box:focus{
    outline: none;
    border: none;
}

.search_box:hover{
    border: 1px solid transparent;
}

.results_wrapper{
    /* background-color: white; */
    /* min-height: 300px; */
    padding: 20px 20px 20px 20px;
    max-width: 100%;
}


.grid_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    max-width: 100%;
    align-items: center;

    max-height: 70vh;
    overflow-y: auto;
}

.preview{
    background-color: rgba(255, 255, 255, 0.412);
    border: 1px solid rgba(0, 0, 0, 0.083);
    height: 280px;
    border-radius: 10px;
    cursor: pointer;
}



.blank_wrapper{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.trigger_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: cyan; */
    flex: 1;
}

.trigger_btn{

    height: 30px;
    padding: 0px 20px;
    border-radius: 10px;
    background-color: transparent;
    color: black;
    border: 1px solid black;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .body_wrapper{
        padding-top: 10vh;
    }
    .work_area{
        width: 95vw;
    }
    .search_box{
        width: 100%;
    }
    .search_box_wrapper{
        padding: 5px 10px;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .search_box_input{
        width: 100px;
        min-width: 100px;
        max-width: 100%;
        font-size: 1.2em;
    }
    .results_wrapper{
        padding: 10px 5px 10px 5px;
        max-height: 70vh;
        overflow: auto;
    }
    .grid_wrapper{
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        row-gap: 5px;
    }
    .preview{
        width: auto;
        height: 45vw;
    }
}

/* @media screen and (max-width: 400px) {
    .results_wrapper{
        padding: 5px;
    }
    .grid_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .preview{
        width: auto;
        height: 90vw;
    }
    
} */