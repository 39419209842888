.wrapper {
  display: flex;
  flex-direction: row;
  /* margin: -15px; */
  gap: 0px;
  margin: -25px;
  border-radius: 10px;
  overflow: hidden;

  max-height: 95vh;
}

.left_box {
  /* height: 95vh; */
  height: auto;
  /* position: relative; */
  background-color: #f1f3f4;

  display: flex;
  flex-direction: column-reverse;
  gap: 10px;

  padding: 10px;
}

.bottom_bar{
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bottom_bar .bottom_bar_btn_group{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.bottom_bar .logo{
    height: 25px;
    object-fit: contain;
}

.preview_loading_wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #00000080;
    z-index: 2;
}

.web_box {
    border-radius: 10px;
    overflow: hidden;
    outline: 1px solid rgba(0, 0, 0, 0.191);

    position: relative;
}

.right_box {
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;

    /* height: 100%; */
    max-height: 100%; 
    overflow-y: auto;

    position: relative;
}


.top_bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 40px;
    gap: 10px;
    margin-top: 12px;
    /* margin-top: 20px; */

    /* background-color: cyan;
    margin: -20px;
    padding: 20px; */
}

.profile_pic{
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: rgba(0, 0, 0, 0.164);
    object-fit: cover;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    line-height: 1;
}

.name_wrapper{
    flex: 1;
    max-width: 60%;
}

.name{
    font-weight: bold;
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle{
    color: rgba(0, 0, 0, 0.426);
    font-style: italic;
    line-height: 1.2;
}

.profile_actions{

}

.title {
  margin-bottom: 7px;
}

.creation_type {
  margin-top: 0;
  margin-bottom: 5px;
  /* background-color: cyan; */
}

.last_updated {
  color: #888;
  margin-top: 0;
  margin-bottom: 5px;
}

.action_wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
}

.select_wrapper {
  display: flex;
  flex-direction: column;
}

.page_control_wrapper {
  margin-top: 20px;
}


.drawer_title_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: -6px -12px -12px -12px;
    font-weight: normal;
}

.drawer_close_btn_wrapper{
    padding-left: 10px;
}




.bottom_pane{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    /* padding: 10px 15px 0 15px; */
    padding-top: 10px;
    background-color: white;
}



@media only screen and (max-width: 700px) {
  .wrapper {
    flex-direction: column;
    margin: -13px;
    /* background-color: cyan; */
  }
  .left_box {
    width: 93vw;
    height: auto;
    padding: 0;
    padding-bottom: 5px;
    flex-direction: column;
    /* flex-direction: column-reverse; */
    background-color: white;
    /* margin-top: -10px; */
  }

  .bottom_bar{
    justify-content: space-between;
  }

  .bottom_bar .logo{
    /* display: none; */
    height: 30px;
  }

  .web_box {
    width: 93vw;
    height: 93vw;
    border: 1px solid rgb(140, 140, 140);
    /* border-radius: 5px; */
    /* object-fit: contain; */
  }

  .page_control_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .right_box {
    padding: 0;
  }
  .creation_type {
    margin-top: -15px;
  }
  
  .bottom_pane{
    padding-bottom: 10px;
  }
}
