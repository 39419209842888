.wrapper {
    height: 100%;
    /* border: 5px dashed red; */
    display: grid;
}

@media only screen and (max-width: 700px) {
    .wrapper {
        /* height: calc(100vh - 100px); */
        height: calc(100vh - 100px);
    }
}
