.wrapper {
    /* padding: 24px; */
    padding: 25px;
    min-height: 360px;
    overflow: auto;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
}

.header_block {
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    align-items: center;
    gap: 20px;
    padding: 15px;
}

.header_img {
    width: 120px;
    border-radius: 5px;
}

.small_head {
    color: palevioletred;
    font-weight: bold;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 7px;
}

.subtitle {
    font-size: 1.2rem;
    margin-top: 0;
    color: rgb(128, 128, 128);
}

.twocol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

@media only screen and (max-width: 1000px) {
    .twocol {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        padding: 10px 3px;
    }
    .header_block {
        flex-direction: column;
    }
    .small_head {
        text-align: center;
    }
    .title {
        text-align: center;
        font-size: 1.2rem;
        margin-bottom: 3px;
    }
    .subtitle {
        text-align: center;
        font-size: 0.9rem;
    }
    .twocol {
        grid-template-columns: 1fr;
    }
}
