.container {
  padding-top: 15px;
  min-height: 700px;
}

.buttons_wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.grid_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 15px;
  /* min-height: 500px; */
  align-items: baseline;
}

.item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 200px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.item_wrapper .img_personal {
  width: 150px;
  height: 190px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.092);
}
.item_wrapper .img_organization {
  width: 180px;
  /* height: 150px; */
  height: 100px;
  object-fit: contain;
}

.item_wrapper .name {
  margin: 7px 0 0 0;
}

.item_wrapper .badge {
  /* background-color: #7fd17f; */
  background-color: #7fb3d1;
  color: white;
  width: auto;
  padding: 2px 4px;
  border-radius: 3px;
  margin: 7px 0 0 0;
}

.no_data{
    color: #afafaf;
}