.card_wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 50px 30px 20px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.name {
    font-size: 1.5rem;
    font-weight: bold;
}

.price {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 20px;
}

.duration {
    font-size: 1.2rem;
    color: gray;
    margin-top: -15px;
}

.points_wrapper {
    margin-top: 30px;
    list-style-type: none;
    list-style-position: outside;
    padding: 0;
    /* padding-left: 15px; */
}

.point {
    margin: 0 0 12px 0;
    padding-left: 10px;
}

.point:before {
    content: "\2713";
    font-size: 1rem;
    padding-right: 10px;
}
