.cgrid {
    width: 100%;
    min-height: 100%;
    display: grid;
    gap: 10px;
    padding: 15px;
    max-width: calc(100% - 30px);
    /* grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr)); */
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    overflow: hidden;
    padding: 15px;
    /* border: 1px solid gray; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 2px;
}

.card:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.square_img {
    /* background-image: url("https://images.news18.com/ibnlive/uploads/2021/07/1627377451_nature-1600x900.jpg"); */
    width: 100%;
    padding-bottom: 100%;
    border-radius: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; /* you change this to "contain" if you don't want the images to be cropped */
}

.card .title {
    font-size: 1rem;
    font-weight: bold;
    color: rgb(73, 73, 73);
    padding-top: 8px;
    padding-bottom: 5px;
    margin: 0;
}

.card .subtitle {
    font-size: 0.8rem;
    font-weight: bold;
    color: rgb(126, 126, 126);
    padding: 0;
    margin: 0;
}

@media only screen and (max-width: 700px) {
    .card {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(70, 70, 70, 0.24);
    }
}
