.wrapper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  /* background */
  background: #2376be; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f14c9f,
    #1783e2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e64e9a,
    #2086df
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.top_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
}

.welcome_text {
  text-align: center;
  font-size: 1.2em;
  margin: 0;
}

.ask_text {
  text-align: center;
  font-size: 1.8em;
  color: white;
  padding: 10px;
  margin: 10px;
}

.items_bar {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 0;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.item_wrapper {
  flex: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 10px;
  color: white;
  font-size: 1.1em;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  user-select: none;
}

.active {
  background-color: white;
  color: black;
  z-index: 3;
}

/* ====== Tabs ============================== */

/* ==================================== */
@media only screen and (max-width: 768px) {
  .wrapper {
    padding-top: 10px;
  }
  .ask_text {
    font-size: 1em;
  }
  .items_bar {
    max-width: 100%;
    overflow: scroll;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .item_wrapper {
    padding: 10px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    white-space: nowrap;
  }
}
