.wrapper{
    /* max-height: 200px; */
    overflow-y: auto;
}

.heading{
    color: #999;
}

.item_wrapper{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    /* padding: 10px; */
    /* border: 1px solid #dadada;
    border-radius: 7px; */
}

.wrapper .item_wrapper:last-child{
    border-bottom: none;
    margin-bottom: 0;
}

.item_wrapper .user{
    color: var(--primary-color);
    margin: 0;
}

.item_wrapper h4{
    margin: 0;
}

.item_wrapper p{
    margin: 0;
    color: rgb(122, 122, 122);
}

.write_wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}