

.wrapper{
    border: 1px solid rgba(0, 0, 0, 0.128);
    display: flex;
    flex-direction: row;
}

.left_box{

}

.preview{
    height: 250px;
    width: 250px;
    min-width: 250px;
    object-fit: contain;
}

.right_box{
    flex: 1;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    padding-bottom: 10px;
}

.title{
    line-height: 1.2em;
    padding: 0;
    margin: 10px 0 10px 0;
}

.tags_wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
}

.tag{
    border: 1px solid rgba(0, 0, 0, 0.325);
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.667);
}


@media screen and (max-width: 600px) {
    .preview{
        height: 150px;
        width: 150px;
        min-width: 150px;
        object-fit: contain;
    }
    
}