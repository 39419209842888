.heading {
  font-size: 1.7rem;
  font-weight: bolder;
  padding-bottom: 20px;
}

.wrapper {
  background-color: white;
  width: 100%;
  /* height: 100vh; */
  padding: 25px;
  overflow: auto;
  box-sizing: border-box;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 202px);
  /* grid-template-columns: repeat(auto-fill, 350px); */
  column-gap: 20px;
  row-gap: 40px;
}

.gridWrapperV {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  /* grid-template-columns: repeat(2, 135px); */
  column-gap: 20px;
  row-gap: 30px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.gridItemImg {
  /* height: 150px; */
  height: 180px;
  background-color: #ebecf0;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.gridItemImgV {
  height: 310px;
  background-color: #ebecf0;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.giImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 4px;

  object-fit: contain;
  /* object-fit: cover; */
}

.giBackImg {
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  /* width: 100%; */
  /* height: 100%; */
  outline: none;
  border: none;
  padding: 4px;

  background-size: cover;
  background-color: #f1fdf3;
}

.giOverley {
  position: absolute;
  /* top: 0; */
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: 0%;
  background-color: #00000071;
  opacity: 0;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* .giOverley:hover {
    opacity: 1;
    height: 100%;
} */

.gridItem:hover .giOverley {
  opacity: 1;
  height: 100%;
}

.gridItemTitle {
  font-size: 0.9rem;
  /* font-weight: 600; */
  margin: 0;
  padding-top: 10px;
  padding-right: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gridItemSubtitle {
  color: rgba(0, 0, 0, 0.29);
  padding-top: 5px;
  font-size: 0.9rem;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.giBtn {
  padding: 7px 12px;
  background-color: white;
  color: black;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.loveBtn {
  position: absolute;
  top: 7px;
  right: 7px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.itemBottomPanel {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.itemBottomTextPanel {
  flex: 1;
  min-width: 0;
}

.itemBottomBtn {
  margin-top: 10px;
  padding: 7px 12px;
  background-color: #ebecf0;
  color: black;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.green_dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(25, 204, 25);
    margin: 0 5px;
    display: inline-block;
}

@media only screen and (max-width: 400px) {
  .gridWrapperV {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    column-gap: 10px;
    row-gap: 20px;
  }
  .gridItemImgV {
    height: 250px;
    border-radius: 10px;
  }
  /* For BlankCreate-------------------------- */
  .gridWrapper {
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    row-gap: 20px;
  }
  /* ----------------------------------- */
  .wrapper {
    padding: 10px;
  }
  .heading {
    font-size: 1.2rem;
  }
  .gridItem {
    height: 45vw;
  }
  .gridItemImg {
    border-radius: 5px;
  }
  .giImg {
    /* object-fit: cover; */
  }

  .gridItemTitle {
    font-size: 0.75rem;
    padding-top: 7px;
  }
  .gridItemSubtitle {
    font-size: 0.7rem;
  }
}
