.screen {
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgb(236, 241, 243);
}

.wrapper {
    /* max-width: 900px; */
    margin: 20px auto;
    padding: 10px 20px;
}

.header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: bolder;
}

.cards_wrapper {
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 30px;
}
