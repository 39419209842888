.wrapper {
    /* background-color: white; */
    /* border-bottom: 1px solid var(--border-color); */
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    z-index: 50;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /* check why its not working */
    -webkit-box-shadow: 0px 3px 5px -1px rgba(140, 140, 140, 1);
    -moz-box-shadow: 0px 3px 5px -1px rgba(140, 140, 140, 1);
    box-shadow: 0px 3px 5px -1px rgba(140, 140, 140, 1);
}

.left_items{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

@media only screen and (max-width: 700px) {
    .wrapper span {
        color: #555;
        font-size: 1.5em;
    }
}
