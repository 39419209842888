.wrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* background-color: #f0f2f5; */
    background-color: white;
    border-bottom: 1px solid var(--border-color);
    /* background-color: transparent; */

    padding-left: 0;
    padding-right: 0;

    /* header height */
    height: 65px;
}

.left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 16px;
}

.title {
    font-size: 1.3rem;
    font-family: "Satoshi", sans-serif;
}

.right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-right: 16px;
}

@media only screen and (max-width: 700px) {
    .wrapper {
        /* max-width: 100%; */
        flex-direction: row;
        gap: 10px;
        height: 55px;
    }
    .title {
        font-size: 1rem;
    }
    .left {
        padding-left: 5px;
    }
    .right {
        padding-right: 5px;
    }
}
