.wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.item{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
    padding: 15px;
    cursor: pointer;
}

.item h4{
    margin: 0;
}

.item p{
    margin-bottom: 0;
    color: #888;
}