.itemsWrapper {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item {
    /* border: 1px solid rgb(219, 219, 219); */
    background-color: rgb(240, 240, 240);
    border-radius: 3px;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 15px;
    border-left: 3px solid rgb(131, 131, 131);
}

.item .name {
    font-weight: bold;
    width: 150px;
}

.item .inner {
    display: flex;
    gap: 15px;
}
.item .type {
    padding: 2px 10px;
    background-color: rgb(16, 124, 88);
    color: white;
    border-radius: 5px;
    user-select: none;
}

.item .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    cursor: pointer;
}
