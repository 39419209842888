

.gallery_wrapper{
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.item{
    height: 120px;
    width: 120px;
    overflow: hidden;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 5px;
    cursor: pointer;
}

.photo{
    height: 100%;
    width: 100%;

    object-fit: contain;
    transition: .2s;
}

.photo:hover{
    transform: scale(1.03);
}

.item:hover,
.selected{
    border: 1px dashed blue;
    outline: 1px dashed blue;
}