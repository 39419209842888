.wrapper{
    padding: 15px;

    display: flex;
    flex-direction: row;
    min-height: 70vh;
    gap: 10px;
}

.sidebar{
    width: 300px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    padding: 15px;
}


.content{
    flex: 1;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}

.label{
    /* font-weight: bold; */
    margin-bottom: 10px;
}

.grid_wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    height: 70px;
}

.grid_item{
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ar_square{
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid black;
}

.ar_landscape{
    width: 70px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid black;
}

.ar_portrait{
    width: 50px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid black;
}