.wrapper {
    background-color: #f5f5f5;
    padding: 15px;
}

.content {
    background-color: white;
    border-radius: 10px;
    /* overflow: clip; */
}

.header_wrapper {
    top: 0;
    z-index: 3;
}

@media only screen and (max-width: 700px) {
    .wrapper {
        padding: 5px;
    }
}
