.wrapper {
    padding: 25px;
    min-height: 360px;
    overflow: auto;
    background-color: white;
    /* border: 1px dotted blue; */
}

@media only screen and (max-width: 768px) {
    .wrapper {
        padding: 3px;
    }
}
