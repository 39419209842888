.main {
    width: 100%;
    height: 90vh;
    background-color: white;
    padding: 20px;
}

.two_col {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.info_col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5px;
}

.preview {
    width: 180px;
    height: 150px;
    background-color: rgb(213, 219, 219);

    outline: none;
    border: none;
    padding: 4px;

    /* object-fit: contain; */
    object-fit: contain;
}

.title_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.title_wrapper h2 {
    color: #505050;
    font-weight: bold;
    margin: 0;
}

.title_wrapper span {
    background-color: rgba(65, 105, 225, 0.61);
    color: white;
    border-radius: 10px;
    padding: 2px 9px;
    font-weight: bold;
    font-size: 0.9em;
}

.last_edited {
    color: rgb(99, 99, 99);
}

.btn_group {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
