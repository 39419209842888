.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 15px;
}

.month_wrapper {
}

.month_name {
  margin: 0;
  text-transform: uppercase;
}

.items_wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  overflow: hidden;
}

.item_wrapper {
  /* width: 250px; */
  height: 150px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 3px;

  position: relative;
  cursor: pointer;
  background-size: 100%;
  transition: 0.4s;
}

.item_wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  background-size: cover;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
  z-index: 0;
}

.item_wrapper:hover::after {
  transform: scale(1.05);
}

.item_wrapper p {
  margin: 0;
}

.item_title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 1em;
  z-index: 1;

  background-color: white;
  box-shadow: 0px 0px 17px 13px rgba(255, 255, 255, 1);
}

.item_dt_wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  background-color: white;
  box-shadow: 0px 0px 21px 21px rgba(255, 255, 255, 1);
}

.item_dt {
  line-height: 1em;
  font-size: 2em;
  font-weight: bold;
}

.item_month_name {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .items_wrapper {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .item_wrapper {
    height: 100px;
  }

  .item_title {
    font-size: 1em;
  }

  .item_dt {
    font-size: 1.5em;
  }

  .item_month_name {
    font-size: 0.8em;
  }
}
