.card {
    display: flex;
    flex-direction: row;
    background-color: white;

    overflow: hidden;
    padding: 15px;
    /* border: 1px solid gray; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 2px;
}

.card img {
    height: 150px;
    object-fit: contain;
    border-radius: 5px;
}

.contentWrapper {
    padding: 0px 15px;
}

.title {
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 0;
    margin-top: 0;
}

.subtitle {
    color: rgb(110, 110, 110);
}

.content {
    margin-top: 10px;
    color: rgb(58, 58, 58);

    /* to break long word */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* show only 4 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.actions_wrapper {
    padding-top: 10px;
}
