.top_wrapper {
    border: 1px solid #ccc;
    display: grid;
    grid-template-columns: 250px 1fr;
}

.wrapper p {
    margin: 0;
}

.left_box {
    padding: 15px;
    border-right: 1px solid #ccc;
}

.right_box {
    padding: 15px;
    position: relative;
}

.bottom_wrapper {
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px 15px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 6px;
    border-bottom-left-radius: 3px;
    color: white;
}
.name {
    margin: 0;
    /* margin-top: 5px; */
    margin-bottom: 10px;
}

.company {
    padding: 0.5em 0;
}

.icon_item {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    line-height: 1.7em;
}

.dt {
    line-height: 1.7em;
    color: #666;
    font-style: italic;
}

.max_lines_5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

@media only screen and (max-width: 800px) {
    .top_wrapper {
        grid-template-columns: 1fr;
    }
    .left_box {
        border: none;
    }
    .right_box {
        border: none;
        border-top: 1px solid #ccc;
    }
}
