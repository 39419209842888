
.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.item{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
}

.item:hover{
    background-color: aliceblue;
}