.wrapper {
    width: 100%;
    padding: 35px 15px;
    box-sizing: border-box;

    border: 1px solid rgb(223, 223, 223);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper i {
    color: rgb(223, 223, 223);
    font-size: 4em;
    padding-bottom: 10px;
}

.title {
    margin: 0;
    color: rgb(106, 106, 106);
    margin-bottom: 10px;
}

.subtitle {
    margin: 0;
    opacity: 0.9;
    margin-bottom: 10px;
}

.content {
    max-width: 80%;
    text-align: center;
    opacity: 0.8;
    margin: 0;
}

.actions{
    margin-top: 15px;
}