.wrapper {
  background-color: white;
  flex: 1;
  padding: 15px 15px 0 15px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 5px 5px 25px 5px;
  }
}

/* ====== Tabs ============================== */

/* ==================================== */
