.button_group{
    display: flex;
    gap: 10px;
}

.creation_picker_wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    padding: 20px 0 10px 0;
}

.creation_item{
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    outline: 1px solid transparent;
}

.selected{
    background-color: #f4f1f1;
    border: 1px solid transparent;
    outline: 1px dashed var(--primary-color);
}

.creation_item img{
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 5px;
}

.creation_item .creation_details{
    height: 100%;
    flex: 1;
    padding-left: 10px;
}

.creation_item .creation_details h4{
    margin: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}