.wrapper {
    position: relative;
    user-select: none;
}

.header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
}

.header i {
    color: #979797;
    font-size: 1.2em;
    z-index: 5;
}

.optionsWrapper {
    position: absolute;
    top: 98%;
    left: 0;
    right: 0;
    transition: 0.3s;
    z-index: 11;

    background-color: white;
    box-shadow: 1px 4px 13px -5px rgba(92, 92, 92, 1);
    border: 1px solid #a8a8a8;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    display: flex;
    flex-direction: column;
    /* max-height: 64px; */
    max-height: 13.8em;
    overflow: auto;
}

.option {
    padding: 0.4em 0.9em;
    border-bottom: 1px solid #e7e7e7;
    cursor: pointer;
    color: black;
    font-weight: normal;
}

.option:hover {
    background-color: #ececec;
}

.selected {
    background-color: royalblue;
    color: white;
}

.chipsWrapper {
    display: flex;
    flex-direction: row;
    gap: 0.25em;
    flex-wrap: wrap;
}

.chip {
    border: 1px solid gray;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 0.2em 0.7em;
    font-weight: normal;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.chip i {
    font-size: 1em;
    color: #979797;
}

.chip i:hover {
    color: rgb(230, 112, 112);
}

.headerMulti {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3em;
    padding-right: 0.8em;
}
