.wrapper {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    background: #eeeeee;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    position: sticky;
    top: 10px;
    /* background-color: rgba(255, 255, 255, 0.445); */
    background-color: white;
}

.heading {
    text-align: center;
    margin-top: 10px;
    /* padding-bottom: 15px; */
}

.logo {
    height: 110px;
    padding-bottom: 25px;
    margin-top: -100px;
    position: sticky;
    top: 10px;
}

.logo_inside_wrapper {
    text-align: center;
}

.logo_inside {
    height: 90px;
    padding-top: 10px;
    padding-bottom: 0px;
}

.card ::placeholder,
.card ::-webkit-input-placeholder,
.card .ant-select-selection__placeholder,
.card .ant-select-selection-placeholder {
    color: rgb(131, 131, 131) !important;
    opacity: 1 !important;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        background-color: white;
    }
    .card {
        box-shadow: none;
    }
    .logo {
        height: 100px;
        padding-bottom: 15px;
    }
}
